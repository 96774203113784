import React from "react";
import { Link } from "react-router-dom";

const CommunityListItem = ({ boardInfo }) => {
  return (
    <li>
      <Link to={`/community/board-detail/${boardInfo.id}`}>
        <h2 className="post_title1">{boardInfo.title}</h2>
        <p className="date">{boardInfo.date}</p>
      </Link>
    </li>
  );
};

export default CommunityListItem;
