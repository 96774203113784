import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CollaboratorSiteListItem from "./CollaboratorSiteListItem";

const CollaboratorSiteList = ({ siteInfoList }) => {
  const setting = {
    dots: false,
    infinite: siteInfoList.length > 6,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...setting} className="related-sites__box">
      {siteInfoList.map((siteInfo) => (
        <CollaboratorSiteListItem siteInfo={siteInfo} key={siteInfo.id} />
      ))}
    </Slider>
  );
};

export default CollaboratorSiteList;
