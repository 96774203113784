import React, { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import MenuContext from "context/menu";
import MobileContext from "context/mobile";

import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import Footer from "../ect/Footer";
import PageTitle from "../ect/PageTitle";
import CommunityMenu from "./CommunityMenu";
import BoardTitle from "./board-template/BoardTitle";
import BoardList from "./board-template/BoardList";
import Paging from "components/ect/Paging";
import BottomNavigation from "components/ect/BottomNavigation";

const CommunityBoard = ({ title, type }) => {
  const { state } = useContext(MenuContext);
  const isMobile = useContext(MobileContext).state.isMobile;
  const [curPage, setCurPage] = useState(1);

  const getCurPage = (pageNum) => {
    setCurPage(pageNum);
  };
  const [totalCount, setTotalCount] = useState(0);
  const [postList, setPostList] = useState(null);

  const getBoardListInfo = useCallback(async () => {
    const url = `${process.env.REACT_APP_COMMUNITY_SERVICE}/api2/admin/community`;

    try {
      const res = await axios.get(url, {
        params: {
          page: curPage,
          type: type,
        },
      });

      if (res.status === 200) {
        const { total_count, list } = res.data;

        setTotalCount(total_count);

        let ary = [];
        for (let i = 0; i < list.length; i++) {
          ary.push({
            id: list[i].idx,
            title: list[i].title,
            date: list[i].createDate.slice(0, 10),
            category: list[i].type,
          });
        }

        setPostList(ary);
      }
    } catch (e) {
      console.log(" ===== ERROR : get board list info ===== ");
      console.log(e);
    }
  }, [curPage, type]);

  const getPressBoardListInfo = useCallback(async () => {
    const url = `${process.env.REACT_APP_COMMUNITY_SERVICE}/api2/admin/press`;

    try {
      const res = await axios.get(url, {
        params: {
          pages: curPage,
          rows: 10,
        },
      });

      if (res.status === 200) {
        const { total_count, list } = res.data;

        setTotalCount(total_count);

        let ary = [];
        for (let i = 0; i < list.length; i++) {
          ary.push({
            id: list[i].idx,
            title: list[i].title,
            date: list[i].createDate.slice(0, 10),
            url: list[i].link,
          });
        }

        setPostList(ary);
      }
    } catch (e) {
      console.log(" ===== ERROR : get board list info ===== ");
      console.log(e);
    }
  }, [curPage]);

  useEffect(() => {
    if (state.communityMenu === 3) {
      getPressBoardListInfo();
    } else {
      getBoardListInfo();
    }
  }, [state.communityMenu, getBoardListInfo, getPressBoardListInfo]);

  if (postList === null) {
    return <p></p>;
  }

  return (
    <div id="container">
      <Header />
      <MenuBar />
      <PageTitle pageTitle="커뮤니티" />
      <section className="explore">
        <div className="container">
          <div className="row">
            <CommunityMenu />

            <div className="col-lg-10">
              <BoardTitle title={title} sum={totalCount} />
              {state.communityMenu === 3 ? (
                <BoardList boardList={postList} now={-1} newWindow={true} />
              ) : (
                <BoardList boardList={postList} now={-1} newWindow={false} />
              )}

              <Paging
                pageNum={curPage}
                getCurPage={getCurPage}
                onePage={5}
                all={totalCount}
              />
            </div>
          </div>
        </div>
      </section>
      {isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default CommunityBoard;
