import React, { useState, useContext } from "react";
import MenuContext from "context/menu";

import NoticeList from "./NoticeList";

const CommunityTemplate = () => {
  const { actions } = useContext(MenuContext);

  const [menu, setMenu] = useState(0);
  const onChangeTapMenu = (menuNum) => {
    actions.setCommunityMenu(menuNum);
    setMenu(menuNum);
  };

  return (
    <div className="col-md-6">
      <section className="tabs-block">
        <div className="container">
          <div className="tabs-block-box">
            <ul className="tabs-block__list">
              <li className="tabs-block__list-item">
                <a
                  className={menu === 0 ? "active-list" : ""}
                  onClick={() => onChangeTapMenu(0)}
                >
                  공지사항
                </a>
              </li>
              {/* <li className="tabs-block__list-item">
                <a
                  className={menu === 1 ? "active-list" : ""}
                  onClick={() => onChangeTapMenu(1)}
                >
                  이벤트
                </a>
              </li> */}
            </ul>
            <div className="tabs-block__content">
              <NoticeList category={menu} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CommunityTemplate;
