import React from "react";
import { Link } from "react-router-dom";

const EventBannerListItem = ({ image, link }) => {
  return (
    <div
      className="item"
      onClick={() => window.open(`http://${link}`, "_blank")}
    >
      {/* <Link to={link}> */}
      <div
        className="event_banner"
        style={{
          cursor: "pointer",
        }}
      >
        <div className="event_banner__gal-box">
          <img className="event_banner__image" src={image} alt="event-banner" />
        </div>
      </div>
      {/* </Link> */}
    </div>
  );
};

export default EventBannerListItem;
