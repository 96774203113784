import React from "react";
import { useHistory } from "react-router";

const EnrollSection = () => {
  const history = useHistory();

  return (
    <section className="section-icon-and-button section-icon-and-button-white">
      <div className="container">
        <div
          className="section-header"
          onClick={() => history.push("/event/add-event")}
          style={{
            cursor: "pointer",
          }}
        >
          <h1 className="section-header__title">등록하기 </h1>
          <p className="section-header__description">
            문화공간과 문화행사를 직접 등록해보세요.
          </p>
        </div>

        <div className="section-icon-and-button__box">
          <div className="row">
            <div
              className="col-lg-6 col-md-6"
              style={{
                cursor: "pointer",
              }}
              onClick={() => history.push("/event/add-event")}
            >
              <div className="icon-button-post">
                <span className="icon-button-post__icon">
                  <i className="la la-compass" aria-hidden="true"></i>
                </span>
                <h2 className="icon-button-post__title">문화행사 등록하기 </h2>
                <p className="sm_description">
                  서초구에서 진행하는
                </p>
                <p className="sm_description">
                  홍보하고 싶은 문화행사를 직접 등록해 보세요.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/place/add-place");
              }}
            >
              <div className="icon-button-post">
                <span className="icon-button-post__icon">
                  <i className="la la-binoculars" aria-hidden="true"></i>
                </span>
                <h2 className="icon-button-post__title">문화공간 등록하기 </h2>
                <p className="sm_description">
                  서초구에서 공유하고 싶은 문화공간을
                </p>
                <p className="sm_description">
                  직접 등록해보세요.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EnrollSection;
