import React, { useState, useEffect, useCallback, useContext } from "react";
import MenuContext from "context/menu";
import MobileContext from "context/mobile";
import { useHistory } from "react-router";
import axios from "axios";
import DOMPurify from "dompurify";

import Footer from "../../ect/Footer";
import Header from "../../ect/Header";
import MenuBar from "../../ect/MenuBar";
import PageTitle from "../../ect/PageTitle";

import CommunityMenu from "../CommunityMenu";
import BoardList from "./BoardList";
import Paging from "components/ect/Paging";
import BottomNavigation from "components/ect/BottomNavigation";

const categories = [
  "notice-board",
  "event-board",
  "news-board",
  "press-release",
];

const replaceString = (string) => {
  return string.replace(/-/gi, ".");
};

const BoardDetail = ({ match }) => {
  const { state } = useContext(MenuContext);
  const isMobile = useContext(MobileContext).state.isMobile;
  const [curPage, setCurPage] = useState(1);

  const getCurPage = (pageNum) => {
    setCurPage(pageNum);
  };

  const history = useHistory();

  const [boardInfo, setBoardInfo] = useState(null);

  const [postList, setPostList] = useState(null);
  const [totalcount, setTotalCount] = useState(0);

  // const getImageSrc = (image) => {
  //   if (image === null) {
  //     return null;
  //   }

  //   return `https://culture.seocho.go.kr/community-service${image}`;
  // };

  const getBoardListInfo = useCallback(
    async (type) => {
      const url = `${process.env.REACT_APP_COMMUNITY_SERVICE}/api2/admin/community`;

      try {
        const res = await axios.get(url, {
          params: {
            page: curPage,
            type: type,
          },
        });

        if (res.status === 200) {
          const { total_count, list } = res.data;
          setTotalCount(total_count);

          let ary = [];
          for (let i = 0; i < list.length; i++) {
            ary.push({
              id: list[i].idx,
              title: list[i].title,
              date: list[i].createDate.slice(0, 10),
              category: list[i].type,
            });
          }

          setPostList(ary);
        }
      } catch (e) {
        console.log(" ===== ERROR : get board list info ===== ");
        console.log(e);
      }
    },
    [curPage]
  );

  useEffect(() => {
    const { postId } = match.params;

    const getDetailInfo = async () => {
      const url = `${process.env.REACT_APP_COMMUNITY_SERVICE}/api2/admin/community/detail/${postId}`;

      try {
        const res = await axios.get(url);

        if (res.status === 200) {
          setBoardInfo({
            id: res.data.idx,
            title: res.data.title,
            date: res.data.createDate.slice(0, 10),
            // period: {
            //   start: "2021-04-05",
            //   end: "2021-05-31",
            // },
            view: res.data.viewCount,
            category: res.data.type,
            content: res.data.moreInformation,
            // image: res.data.realImagesPath,
            phone: res.data.phone,
            contactName: res.data.contactName,
            files: res.data.files,
            folder: res.data.folder,
            contentId: res.data.id,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    getDetailInfo();

    switch (state.communityMenu) {
      case 0:
        getBoardListInfo("NOTICE");
        break;
      case 1:
        getBoardListInfo("EVENT");
        break;
      case 2:
        getBoardListInfo("NEWS");
        break;
      default:
        break;
    }
  }, [state.communityMenu, getBoardListInfo, match.params]);

  if (!boardInfo || !postList) {
    return null;
  }

  return (
    <div id="container">
      <Header />
      <MenuBar />
      <PageTitle pageTitle="커뮤니티" />

      <section className="explore">
        <div className="container">
          <div className="row">
            <CommunityMenu />

            <div className="col-lg-10">
              <div className="section-title-sm">
                <button
                  className="btn-sc__item-reply"
                  onClick={() =>
                    history.push(
                      `/community/${categories[state.communityMenu]}`
                    )
                  }
                  style={{ backgroundColor: "white" }}
                >
                  &lt; 목록
                </button>
                <hr />
              </div>

              <div className="section-title-lg2 ">
                <h1 className="listing-detail-page__content-title">
                  {boardInfo.title}
                </h1>

                <div className="sc-content-info">
                  <p className="post__info-time float-left">
                    {/* <i className="fa fa-clock-o" aria-hidden="true"></i>
                    {replaceString(boardInfo.period.start)}~
                    {replaceString(boardInfo.period.end)} */}
                  </p>

                  <div className="float-right">
                    <span>글번호 : {boardInfo.id}</span>
                    <span>등록일 : {replaceString(boardInfo.date)}</span>
                    <span>조회수 : {boardInfo.view}</span>
                  </div>
                </div>
              </div>

              <div className="sc-content">
                {/* <div className="sc-event-d-box">
                  <img src={getImageSrc(boardInfo.image)} />
                </div> */}
                <br />
                <div
                  className="listing-detail__content-description"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(boardInfo.content),
                  }}
                ></div>
              </div>
              <div className="sc-content-info">
                {boardInfo.files ? (
                  <>
                    첨부파일 : {boardInfo.files}{" "}
                    <a
                      className="btn-sc__item-reply"
                      href={`https://culture.seocho.go.kr:8443/upload-service/api/download/community/file?id=${boardInfo.contentId}`}
                    >
                      다운로드{" "}
                    </a>
                  </>
                ) : null}

                <div className="float-right">
                  <span>작성자 : {boardInfo.contactName}</span>
                  <span>문의처 : {boardInfo.phone}</span>
                </div>
              </div>
              <div className="listing-detail__content-box">
                <BoardList boardList={postList} now={boardInfo.id} />

                <Paging
                  pageNum={curPage}
                  getCurPage={getCurPage}
                  onePage={5}
                  all={totalcount}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default BoardDetail;
