import React, { useContext } from "react";

import Header from "components/ect/Header";
import Footer from "components/ect/Footer";
import MenuBar from "components/ect/MenuBar";
import PageTitle from "components/ect/PageTitle";

import MobileContext from "context/mobile";
import BottomNavigation from "components/ect/BottomNavigation";

const IntroduceView = () => {
  const { state } = useContext(MobileContext);
  return (
    <>
      <div id="container">
        <Header />
        <MenuBar />
        <PageTitle pageTitle="서리풀 악기거리" />
        <section className="explore">
          <div id="container">
            <div
              className="subcon_wrap"
              style={{
                margin: "0px auto 4rem",
                padding: "0px",
                border: "0px",
                fontSize: "16px",
                verticalAlign: "baseline",
                lineHeight: "25.6px",
                boxSizing: "border-box",
                maxWidth: "80rem",
                position: "relative",
                color: "rgb(84, 84, 84)",
                fontFamily: "Nanum Gothic, sans-serif",
                fontStyle: "normal",
                fontVariantLigatures: "normal",
                fontVariantCaps: "normal",
                fontWeight: "400",
                letterSpacing: "normal",
                orphans: "2",
                textAlign: "start",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: "2",
                wordSpacing: "0px",
                " -webkit-text-stroke-width": "0px",
                textDecorationThickness: "initial",
                textDecorationStyle: "initial",
                textDecorationColor: "initial",
              }}
            >
              <div
                className="wrapper"
                style={{
                  margin: "0px auto",
                  padding: "0px",
                  border: "0px",
                  fontSize: "16px",
                  verticalAlign: "baseline",
                  lineHeight: "25.6px",
                  boxSizing: "border-box",
                  maxWidth: "80rem",
                }}
              >
                <div
                  className="inner"
                  style={{
                    margin: "0px auto",
                    padding: "0px",
                    border: "0px",
                    fontSize: "16px",
                    verticallign: "baseline",
                    lineHeight: "25.6px",
                    boxSizing: "border-box",
                    maxWidth: "68.75rem",
                  }}
                >
                  <div
                    className="sub1010"
                    style={{
                      margin: "0px",
                      padding: "0px",
                      border: "0px",
                      fontSize: "16px",
                      verticalAlign: "baseline",
                      lineHeight: "25.6px",
                      boxSizing: "border-box",
                    }}
                  >
                    <div
                      className="intro row clearfix"
                      style={{
                        margin: "0px",
                        padding: "0px",
                        border: "0px",
                        fontSize: "16px",
                        verticalAlign: "baseline",
                        lineHeight: "25.6px",
                        boxSizing: "border-box",
                        width: "1100px",
                        position: "relative",
                        background:
                          'url("/design/default/img/sub/sub1010_bg.png") center top / 100% no-repeat',
                      }}
                    >
                      <div
                        className="intro_mov"
                        style={{
                          margin: "0px 0px 5rem",
                          padding: "0px",
                          border: "0px",
                          fontSize: "16px",
                          verticalAlign: "baseline",
                          lineHeight: "25.6px",
                          boxSizing: "border-box",
                          width: "1100px",
                        }}
                      >
                        <video
                          alt="서초음악문화지구"
                          autoplay=""
                          controls=""
                          muted=""
                          playsinline=""
                          src="http://seoripulac.or.kr/design/default/img/sub/sub1010_mov.mp4"
                          style={{
                            margin: "0px",
                            padding: "0px",
                            border: "0px",
                            fontSize: "16px",
                            verticalAlign: "baseline",
                            lineHeight: "25.6px",
                            boxSizing: "border-box",
                            width: "1100px",
                          }}
                        >
                          <br />
                        </video>
                      </div>
                      <div
                        className="intro_tit"
                        style={{
                          margin: "0px",
                          padding: "0px",
                          border: "0px",
                          fontSize: "16px",
                          verticalAlign: "baseline",
                          lineHeight: "25.6px",
                          boxSizing: "border-box",
                          width: "550px",
                          float: "left",
                          height: "25rem",
                          position: "relative",
                        }}
                      >
                        <div
                          className="bd_wrap"
                          style={{
                            margin: "0px",
                            padding: "0px",
                            borderWidth: "0.625rem 0px 0.625rem 0.625rem",
                            borderRightStyle: "initial",
                            borderTopColor: "rgb(215, 215, 215)",
                            borderRightColor: "initial",
                            borderBottomColor: "rgb(215, 215, 215)",
                            borderLeftColor: "rgb(215, 215, 215)",
                            borderImage: "initial",
                            fontSize: "16px",
                            verticalAlign: "baseline",
                            lineHeight: "25.6px",
                            boxSizing: "border-box",
                            width: "25rem",
                            height: "400px",
                            position: "relative",
                          }}
                        >
                          &nbsp;
                        </div>
                        <h3
                          style={{
                            margin: "0px",
                            padding: "6rem 0px 0px 6rem",
                            border: "0px",
                            fontSize: "1.745rem",
                            verticalAlign: "baseline",
                            lineHeight: "3.15rem",
                            boxSizing: "border-box",
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            fontFamily: "NanumSquare, sans-serif",
                            fontWeight: "200",
                            color: "rgb(146, 146, 146)",
                          }}
                        >
                          <b
                            style={{
                              margin: "0px",
                              padding: "0px",
                              border: "0px",
                              fontSize: "2.65rem",
                              verticalAlign: "baseline",
                              lineHeight: "inherit",
                              boxSizing: "border-box",
                              fontWeight: "400",
                              color: "rgb(0 0 0)",
                              letterSpacing: "-0.125rem",
                            }}
                          >
                            예술가
                          </b>
                          와&nbsp;
                          <b
                            style={{
                              margin: "0px",
                              padding: "0px",
                              border: "0px",
                              fontSize: "2.65rem",
                              verticalAlign: "baseline",
                              lineHeight: "inherit",
                              boxSizing: "border-box",
                              fontWeight: "400",
                              color: "rgb(0 0 0)",
                              letterSpacing: "-0.125rem",
                            }}
                          >
                            공간&middot;지역
                          </b>
                          이 만나
                          <br />
                          예술이&nbsp;
                          <b
                            style={{
                              margin: "0px",
                              padding: "0px",
                              border: "0px",
                              fontSize: "2.65rem",
                              verticalAlign: "baseline",
                              lineHeight: "inherit",
                              boxSizing: "border-box",
                              fontWeight: "400",
                              color: "rgb(162, 162, 162)",
                              letterSpacing: "-0.125rem",
                            }}
                          >
                            끊임없이
                          </b>
                          <br />
                          <b
                            style={{
                              margin: "0px",
                              padding: "0px",
                              border: "0px",
                              fontSize: "2.65rem",
                              verticalAlign: "baseline",
                              lineHeight: "inherit",
                              boxSizing: "border-box",
                              fontWeight: "400",
                              color: "rgb(0, 0, 0)",
                              letterSpacing: "-0.125rem",
                            }}
                          >
                            창조
                          </b>
                          되고&nbsp;
                          <b
                            style={{
                              margin: "0px",
                              padding: "0px",
                              border: "0px",
                              fontSize: "2.65rem",
                              verticalAlign: "baseline",
                              lineHeight: "inherit",
                              boxSizing: "border-box",
                              fontWeight: "400",
                              color: "rgb(0, 0, 0)",
                              letterSpacing: "-0.125rem",
                            }}
                          >
                            해석
                          </b>
                          되는
                          <br />
                          <b
                            style={{
                              margin: "0px",
                              padding: "0px",
                              border: "0px",
                              fontSize: "2.785rem",
                              verticalAlign: "baseline",
                              lineHeight: "3.15rem",
                              boxSizing: "border-box",
                              fontWeight: "400",
                              color: "rgb(88, 88, 88)",
                              letterSpacing: "-0.125rem",
                            }}
                          >
                            서초음악문화지구
                          </b>
                        </h3>
                      </div>
                      <div
                        className="intro_img"
                        style={{
                          margin: "0px",
                          padding: "0px",
                          border: "0px",
                          fontSize: "16px",
                          verticalAlign: "baseline",
                          lineHeight: "25.6px",
                          boxSizing: "border-box",
                          width: "550px",
                          float: "left",
                        }}
                      >
                        <img
                          alt="서초음악문화지구"
                          src="http://seoripulac.or.kr/design/default/img/sub/sub1010_img.png"
                          style={{
                            padding: "0px",
                            border: "none",
                            fontSize: "16px",
                            lineHeight: "25.6px",
                            boxSizing: "border-box",
                            maxWidth: "100%",
                          }}
                        />
                      </div>
                      <div
                        className="intro_text"
                        style={{
                          margin: "auto",
                          padding: "6rem 0px",
                          border: "0px",
                          fontSize: "1.05rem",
                          verticalAlign: "baseline",
                          lineHeight: "2rem",
                          boxSizing: "border-box",
                          maxWidth: "940px",
                          letterSpacing: "-0.0625rem",
                          color: "rgb(50, 50, 50)",
                          clear: "both",
                          wordBreak: "keep-all",
                        }}
                      >
                        문화예술기관 및 문화공간, 악기상가 등 다양한 음악시설이
                        밀집되어 있는 서초구는&nbsp;
                        <strong
                          style={{
                            margin: "0px",
                            padding: "0px",
                            border: "0px",
                            fontSize: "16.8px",
                            verticalAlign: "baseline",
                            lineHeight: "26.88px",
                            boxSizing: "border-box",
                          }}
                        >
                          대한민국의 클래식 음악문화의 중심지
                        </strong>
                        로 자리 잡았습니다. 서초구는 이러한 문화예술자원을
                        최대한 활용하여&nbsp;
                        <strong
                          style={{
                            margin: "0px",
                            padding: "0px",
                            border: "0px",
                            fontSize: "16.8px",
                            verticalAlign: "baseline",
                            lineHeight: "26.88px",
                            boxSizing: "border-box",
                          }}
                        >
                          클래식 문화 명소
                        </strong>
                        를 조성하기 위해 노력하고 있으며 그 노력의 성과로&nbsp;
                        <strong
                          style={{
                            margin: "0px",
                            padding: "0px",
                            border: "0px",
                            fontSize: "16.8px",
                            verticalAlign: "baseline",
                            lineHeight: "26.88px",
                            boxSizing: "border-box",
                          }}
                        >
                          2018년 5월
                        </strong>{" "}
                        서초구가&nbsp;
                        <strong
                          style={{
                            margin: "0px",
                            padding: "0px",
                            border: "0px",
                            fontSize: "16.8px",
                            verticalAlign: "baseline",
                            lineHeight: "26.88px",
                            boxSizing: "border-box",
                            color: "rgb(0 0 0)",
                          }}
                        >
                          서초음악문화지구
                        </strong>
                        로 지정되었습니다. 서초구는&nbsp;
                        <strong
                          style={{
                            margin: "0px",
                            padding: "0px",
                            border: "0px",
                            fontSize: "16.8px",
                            verticalAlign: "baseline",
                            lineHeight: "26.88px",
                            boxSizing: "border-box",
                          }}
                        >
                          음악예술가
                        </strong>
                        들의 창조성이 무한히 발현되고, 지역주민들의 일상
                        속&nbsp;
                        <strong
                          style={{
                            margin: "0px",
                            padding: "0px",
                            border: "0px",
                            fontSize: "16.8px",
                            verticalAlign: "baseline",
                            lineHeight: "26.88px",
                            boxSizing: "border-box",
                          }}
                        >
                          문화예술 활동
                        </strong>
                        이 지속되며 음악문화시설이 보존 육성되는,&nbsp;
                        <strong
                          style={{
                            margin: "0px",
                            padding: "0px",
                            border: "0px",
                            fontSize: "16.8px",
                            verticalAlign: "baseline",
                            lineHeight: "26.88px",
                            boxSizing: "border-box",
                          }}
                        >
                          사람과 공간을 연결시켜 예술적가치가 창출되는
                          서초음악문화지구로 발전
                        </strong>
                        시켜 나가고자 합니다.
                      </div>
                      <div
                        className="intro_tit fr"
                        style={{
                          margin: "0px",
                          padding: "0px",
                          border: "0px",
                          fontSize: "16px",
                          verticalAlign: "baseline",
                          lineHeight: "25.6px",
                          boxSizing: "border-box",
                          width: "550px",
                          float: "right",
                          height: "25rem",
                          position: "relative",
                        }}
                      >
                        <div
                          className="bd_wrap"
                          style={{
                            margin: "0px 0px 0px 9rem",
                            padding: "0px",
                            borderLeft: "none",
                            borderImage: "initial",
                            fontSize: "16px",
                            verticalAlign: "baseline",
                            lineHeight: "25.6px",
                            boxSizing: "border-box",
                            width: "25rem",
                            height: "400px",
                            position: "relative",
                          }}
                        >
                          &nbsp;
                        </div>
                        <h3
                          style={{
                            margin: "0px 0px 0px 7rem",
                            padding: "7rem 6rem 0px 0px",
                            border: "0px",
                            fontSize: "1.745rem",
                            verticalAlign: "baseline",
                            lineHeight: "3.15rem",
                            boxSizing: "border-box",
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            fontFamily: "NanumSquare, sans-serif",
                            fontWeight: "400",
                            color: "rgb(146, 146, 146)",
                            textAlign: "right",
                          }}
                        >
                          <b
                            style={{
                              margin: "0px",
                              padding: "0px",
                              border: "0px",
                              fontSize: "2.65rem",
                              verticalAlign: "baseline",
                              lineHeight: "inherit",
                              boxSizing: "border-box",
                              fontWeight: "400",
                              color: "rgb(0 0 0)",
                              letterSpacing: "-0.125rem",
                            }}
                          >
                            서초음악문화지구
                          </b>
                          의<br />
                          <b
                            style={{
                              margin: "0px",
                              padding: "0px",
                              border: "0px",
                              fontSize: "2.65rem",
                              verticalAlign: "baseline",
                              lineHeight: "inherit",
                              boxSizing: "border-box",
                              fontWeight: "400",
                              color: "rgb(0 0 0)",
                              letterSpacing: "-0.125rem",
                            }}
                          >
                            새로운 이름
                          </b>
                          :<br />
                          <b
                            style={{
                              margin: "0px",
                              padding: "0px",
                              border: "0px",
                              fontSize: "2.785rem",
                              verticalAlign: "baseline",
                              lineHeight: "3.15rem",
                              boxSizing: "border-box",
                              fontWeight: "400",
                              color: "rgb(88, 88, 88)",
                              letterSpacing: "-0.125rem",
                            }}
                          >
                            서리풀악기거리
                          </b>
                        </h3>
                      </div>
                      <div
                        className="intro_img fl"
                        style={{
                          margin: "0px",
                          padding: "0px",
                          border: "0px",
                          fontSize: "16px",
                          verticalAlign: "baseline",
                          lineHeight: "25.6px",
                          boxSizing: "border-box",
                          width: "550px",
                          float: "left",
                          textAlign: "center",
                        }}
                      >
                        <img
                          alt="서리풀악기거리"
                          src="http://seoripulac.or.kr/design/default/img/sub/sub1010_img3.png"
                          style={{
                            padding: "0px",
                            border: "none",
                            fontSize: "16px",
                            lineHeight: "25.6px",
                            boxSizing: "border-box",
                            maxWidth: "100%",
                          }}
                        />
                        {/* <span
                          style={{
                            margin: "0px",
                            padding: "20px 0px 0px",
                            border: "0px",
                            fontSize: "0.8rem",
                            lineHeight: "20.48px",
                            boxSizing: "border-box",
                            display: "block",
                            textAlign: "center",
                            color: "rgb(85, 85, 85)",
                          }}
                        >
                          ※ 소리나는 대로 발음하다 보면 생기는 자연발생적인 이름
                          악&apos;끼&apos;거리와,
                          <br />
                          청년음악인들이 맘껏 예술인의 &apos;끼&apos;를 펼친다는
                          중의적 의미
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div align="center" style={{ textAlign: "center" }}>
                <a
                  className="tab-form__submit"
                  onClick={() =>
                    window.open("http://seoripulac.or.kr/main/", "_blank")
                  }
                >
                  사이트로 이동
                </a>
              </div>
            </div>
            <section
              style={{
                margin: "0px",
                padding: "1rem 0px",
                borderWidth: "0.0625rem 0px 0px",
                borderRightStyle: "initial",
                borderBottomStyle: "initial",
                borderLeftStyle: "initial",
                borderTopColor: "rgb(215, 215, 215)",
                borderRightColor: "initial",
                borderBottomColor: "initial",
                borderLeftColor: "initial",
                borderImage: "initial",
                fontSize: "16px",
                lineHeight: "25.6px",
                boxSizing: "border-box",
                display: "block",
                width: "1424px",
                background: "rgb(255, 255, 255)",
                overflow: "hidden",
                color: "rgb(84, 84, 84)",
                fontFamily: '"Nanum Gothic", sans-serif',
                fontStyle: "normal",
                fontVariantigatures: "normal",
                fontVariantCaps: "normal",
                fontWeight: "400",
                letterSpacing: "normal",
                orphans: "2",
                textAlign: "start",
                textIndent: "0px",
                textTransform: "none",
                whiteSpace: "normal",
                widows: "2",
                wordSpacing: "0px",
                "-webkit-text-stroke-width": "0px",
                textDecorationThickness: "initial",
                textDecorationStyle: "initial",
                textDecorationColor: "initial",
              }}
            >
              <div
                className="wrapper clearfix"
                style={{
                  margin: "0px auto",
                  padding: "0px",
                  border: "0px",
                  fontSize: "16px",
                  verticalAlign: "baseline",
                  lineHeight: "25.6px",
                  boxSizing: "border-box",
                  maxWidth: "80rem",
                }}
              >
                <article
                  className="max_width"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    border: "0px",
                    fontSize: "16px",
                    lineHeight: "25.6px",
                    boxSizing: "border-box",
                    display: "block",
                  }}
                >
                  <br className="Apple-interchange-newline" />
                </article>
              </div>
            </section>
          </div>
        </section>
      </div>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </>
  );
};

export default IntroduceView;
