import React from "react";
import CommunityTemplate from "./community-template/CommunityTemplate";
import EventBannerList from "./community-template/EventBannerList";

const NoticeAndBanner = ({ noticeEventBanner }) => {
  return (
    <section className="section-notice-and-event">
      <div className="container">
        <div className="row">
          <CommunityTemplate />
          <EventBannerList noticeEventBanner={noticeEventBanner} />
        </div>
      </div>
    </section>
  );
};

export default NoticeAndBanner;
