import React, { useState, useEffect } from "react";
import axios from "axios";

import CollaboratorSiteList from "./collaboratorSite-template/CollaboratorSiteList";

const CollaboratorSite = () => {
  const [siteList, setSiteList] = useState([]);

  useEffect(() => {
    const getPartnersList = async () => {
      const url = `${process.env.REACT_APP_COMMUNITY_SERVICE}/api2/admin/partners`;

      try {
        const res = await axios.get(url, {
          params: {
            rows: 10,
            pages: 1,
          },
        });

        if (res.status === 200) {
          let ary = [];

          for (let i = 0; i < res.data.list.length; i++) {
            ary.push({
              id: res.data.list[i].idx,
              logoImgSrc: res.data.list[i].thumb,
              folder: res.data.list[i].folder,
            });
          }

          setSiteList(ary);
        }
      } catch (e) {
        console.log("===== 문화 파트너스 목록 조회 실패 =====");
        console.log(e);
      }
    };

    getPartnersList();
  }, []);

  return (
    <section className="section-collaborator-site">
      <div className="container">
        <div className="section-header">
          <h1 className="section-header__title">문화 파트너스 </h1>
        </div>

        <CollaboratorSiteList siteInfoList={siteList} />
      </div>
    </section>
  );
};

export default CollaboratorSite;
