import React from "react";
import { Link } from "react-router-dom";

const CollaboratorSiteListItem = ({ siteInfo }) => {
  return (
    <div className="item">
      <div className="related-sites">
        <Link to={`/community/related-sites-detail/${siteInfo.id}`}>
          <img
            className="related-sites__image"
            src={`${process.env.REACT_APP_COMMUNITY_SERVICE}/related_sites/${siteInfo.folder}/${siteInfo.logoImgSrc}`}
            alt="related-sites-list-image"
          />
        </Link>
      </div>
    </div>
  );
};

export default CollaboratorSiteListItem;
