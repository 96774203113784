import React from "react";
import ButtonAndContent from "./ButtonAndContent";
import ButtonAndContent2 from "./ButtonAndContent2";

const MainSearch = () => {
  return (
    <section className="section-icon-and-button section-icon-and-button-white banner">
      <div className="container">
        <div className="section-icon-and-button__box">
          <div className="row">
            <ButtonAndContent buttonName="행사 맞춤 검색" url="/event/event-all" />
            <ButtonAndContent2 buttonName="내 주변 검색" url="/common/search" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSearch;
