import React from "react";
import { Link } from "react-router-dom";

const styles = {
  cursor: "pointer",
};

const ButtonAndContent = ({ buttonName, url }) => {
  return (
    <div className="col-lg-6 col-md-6">
      <div className="icon-button-post">
        <Link to={url}>
          <button
            className="btn-default btn-default-red"
            type="button"
            style={styles}
          >
            {buttonName}
          </button>
        </Link>
        <br />
        <br />
        <p className="sm_description">
          서초구청에서 진행하는
        </p>
        <p className="sm_description">
          다양한 행사를 둘러보세요
        </p>
      </div>
    </div>
  );
};

export default ButtonAndContent;
