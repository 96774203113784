import React, { useContext } from "react";
import MobileContext from "context/mobile";

import Footer from "components/ect/Footer";
import Header from "components/ect/Header";
import MenuBar from "components/ect/MenuBar";
import BottomNavigation from "components/ect/BottomNavigation";

const UserGuide = () => {
    const { state } = useContext(MobileContext);
    return (
        <div id="container">
            <Header />
            <MenuBar />

            <section className="page-title">
                <div className="container">
                    <h1 className="page-title__title">사용자 가이드</h1>

                    <ul className="single-post__list">
                        <li className="single-post__list-item">
                            <i className=""></i>
                            <span></span>
                        </li>
                        <li className="single-post__list-item">
                            <i className=""></i>
                            <a href="#"></a>
                        </li>
                    </ul>
                </div>
            </section>
            <section className="explore">
                <div className="container">
                    <ul className="single-post__list tabs-block2__list mt-40">
                        <li className="single-post__list-item">
                            <a className="tab-form__submit" href="#cont_join">회원가입</a>
                        </li>
                        <li className="single-post__list-item">
                            <a className="tab-form__submit" href="#event_search">행사 맞춤 검색</a>
                        </li>
                        <li className="single-post__list-item">
                            <a className="tab-form__submit" href="#sarch_place">내 주변 검색</a>
                        </li>
                        <li className="single-post__list-item">
                            <a className="tab-form__submit" href="#add_event">문화행사 등록 신청하기</a>
                        </li>
                        <li className="single-post__list-item">
                            <a className="tab-form__submit" href="#add_place">문화공간 등록 신청하기</a>
                        </li>
                        <li className="single-post__list-item">
                            <a className="tab-form__submit" href="#request_event">문화행사 예약 신청하기</a>
                        </li>
                        <li className="single-post__list-item">
                            <a className="tab-form__submit" href="#request_place">문화공간 예약 신청하기</a>
                        </li>
                    </ul>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="sc-content us_pg">
                                <div id="contents mt-20">
                                    <a className="h2" id="cont_join">1. 회원가입</a>
                                    <hr></hr>
                                    <img src="/images/ug_page-0001.jpg" alt="" title="" />
                                    <a className="h2" id="event_search">2. 행사 맞춤검색</a>
                                    <hr></hr>
                                    <img src="/images/ug_page-0002.jpg" alt="" title="" />
                                    <a className="h2" id="sarch_place">3. 내 주변 검색</a>
                                    <hr></hr>
                                    <img src="/images/ug_page-0003.jpg" alt="" title="" />
                                    <a className="h2" id="add_event">4. 문화행사 등록 신청하기</a>
                                    <hr></hr>
                                    <img src="/images/ug_page-0004.jpg" alt="" title="" />
                                    <a className="h2" id="add_place">5. 문화공간 등록 신청하기</a>
                                    <hr></hr>
                                    <img src="/images/ug_page-0005.jpg" alt="" title="" />
                                    <a className="h2" id="request_event">6. 문화행사 예약 신청하기</a>
                                    <hr></hr>
                                    <img src="/images/ug_page-0006.jpg" alt="" title="" />
                                    <a className="h2" id="request_place">7. 문화공간 예약 신청하기</a>
                                    <hr></hr>
                                    <img src="/images/ug_page-0007.jpg" alt="" title="" />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {state.isMobile ? <BottomNavigation /> : ""}
            <Footer />
        </div>
    );
};

export default UserGuide;
