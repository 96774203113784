import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import CommunityListItem from "./CommunityListItem";

const NoticeList = ({ category }) => {
  const [boardInfo, setBoardInfo] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const getBoardListInfo = async () => {
      const url = `${process.env.REACT_APP_COMMUNITY_SERVICE}/api2/admin/community`;

      try {
        const res = await axios.get(url, {
          params: {
            page: 1,
            type: category === 0 ? "NOTICE" : "EVENT",
          },
        });

        if (res.status === 200) {
          const { total_count, list } = res.data;

          setTotalCount(total_count);

          let ary = [];
          for (let i = 0; i < list.length; i++) {
            ary.push({
              id: list[i].idx,
              title: list[i].title,
              date: list[i].createDate.slice(0, 10),
              category: list[i].type,
            });
          }

          setBoardInfo(ary);
        }
      } catch (e) {
        console.log(" ===== ERROR : get board list info ===== ");
        console.log(e);
      }
    };

    getBoardListInfo();
  }, [category]);

  return (
    <div className="tabs-block__content-tab owl-wrapper" id="notice-tab">
      <div className="event-post">
        <ul className="main-notice-list">
          {boardInfo.map((item) => (
            <CommunityListItem boardInfo={item} key={item.id} />
          ))}
          <Link
            className="text-btn"
            to={
              {
                0: "/community/notice-board",
                1: "/community/event-board",
              }[category]
            }
          >
            더 보기 <span>({totalCount})</span>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default NoticeList;
