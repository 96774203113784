import React from "react";
import { Link } from "react-router-dom";

const BottomNavigation = () => {
  return (
    <div className="bottom-navigation">
      <ul className="bottom-navigation__icons">
        <li>
          <Link to="/main">
            <img src="/images/house.png" alt="" title="" />
            <span>홈</span>
          </Link>
        </li>
        <li>
          <Link to="/event/event-all">
            <img src="/images/search.png" alt="" title="" />
            <span>맞춤검색</span>
          </Link>
        </li>
        <li>
          <Link to="/common/search">
            <img src="/images/map.png" alt="" title="" />
            <span>주변검색</span>
          </Link>
        </li>
        <li>
          <Link to="/my-page/user-alert-setting">
            <img src="/images/notification.png" alt="" title="" />
            <span>알림</span>
            {/* <i>2</i> */}
          </Link>
        </li>
        <li>
          <Link to="/my-page/user-info">
            <img src="/images/user.png" alt="" title="" />
            <span>프로필</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default BottomNavigation;
