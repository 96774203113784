import React from "react";
import EventBannerListItem from "./EventBannerListItem";

const EventBannerList = ({ noticeEventBanner }) => {
  const getImgSrc = (image) => {
    if (image) {
      return `${process.env.REACT_APP_COMMUNITY_SERVICE}/main/${image}`;
    } else {
      return process.env.PUBLIC_URL + "/upload/event-banner2.jpg";
    }
  };

  return (
    <div className="col-md-6">
      <div className="section-notice-and-event-banner">
        <div className=" owl-wrapper ">
          <div className="owl-carousel mt-100" data-num="1">
            {noticeEventBanner.map((bannerListItem) => (
              <EventBannerListItem
                image={getImgSrc(bannerListItem.image)}
                link={bannerListItem.link}
                key={bannerListItem.id}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventBannerList;
